import React, { AriaAttributes, memo } from 'react';
import clsx from 'clsx';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { suggestionsList, suggestionsTitle, suggestionsWrapper, dividerTop } from './suggestion-list-styles';

export type SuggestionsListProps = {
  withTitle: boolean;
  withDivider?: boolean;
  title: string;
  suggestionSelector: string;
  'data-qa': string;
} & AriaAttributes;

export const SuggestionsList: React.FC<SuggestionsListProps> = memo((props) => {
  const {
    withTitle,
    suggestionSelector,
    title,
    'aria-label': label,
    children,
    withDivider = false,
    'data-qa': dataQA,
  } = props;
  const count = React.Children.count(children);
  const { t } = useTranslation();

  if (count === 0) return null;

  return withTitle ? (
    <div className={clsx(suggestionsWrapper, suggestionSelector, { [dividerTop]: withDivider })}>
      <Typography variant="overline" className={clsx(suggestionsTitle, suggestionSelector)}>
        {title}
      </Typography>
      <div
        role="listbox"
        id="srp_search-listbox"
        aria-label={label ?? title}
        className={clsx(suggestionsList, suggestionSelector)}
        data-qa={dataQA}
      >
        {children}
      </div>
    </div>
  ) : (
    <div className={clsx(suggestionsWrapper, suggestionSelector, { [dividerTop]: withDivider })}>
      <div
        role="listbox"
        id="srp_search-listbox"
        aria-label={t('header.searchbar.services-aria')}
        data-qa={dataQA}
      >
        {children}
      </div>
    </div>
  );
});
