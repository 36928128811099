import { Suggestion } from 'types/suggestions';
import { rrHelper } from 'utils/retail-rocket';
import { analytics, patchUrlByLocale } from 'utils';
import { ProductRedirectionEvents } from 'actions';
import { SEARCH_OVERRIDES } from './overrides';
import { SearchHistoryItem } from '../use-search-history-context';

export const getSearchParams = (suggestion: Suggestion | null, inputValue: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append('q', inputValue);

  if (suggestion?.phrase) {
    searchParams.set('q', suggestion.phrase);
    searchParams.append('suggest', 'true');
  }
  return searchParams;
};

const isKzDomain = () => {
  const { host } = global?.location || { host: '' };
  return host.endsWith('.kz') || host.endsWith('lmkz.tech');
};

export const normalizeInput = (str: string) => {
  return str.toLowerCase().trim();
};

const getOverride = (input: string, selectedSuggestion: Suggestion | null): string | null => {
  const normalizedInput = normalizeInput(input);
  if (!selectedSuggestion && !isKzDomain() && SEARCH_OVERRIDES[normalizedInput]) {
    return SEARCH_OVERRIDES[normalizedInput];
  }
  return null;
};

export const handleSubmit = (
  e: React.FormEvent<HTMLFormElement>,
  value: string,
  sanitizedValue: string,
  isLmcode: boolean,
  productRedirectionUrl: string | null | undefined,
  productRedirectionUrlLoading: boolean | undefined,
  selectedSuggestion: Suggestion | null,
  currentRegionId: string | undefined,
  setIsSubmitted: (value: React.SetStateAction<boolean>) => void,
  addHistoryFromSubmit: (value: string, sanitizedValue: string, suggestion: Suggestion | null) => void,
  addHistoryItem: (item: SearchHistoryItem) => void,
  dispatch: (event: string, payload: unknown) => void,
  navigate: (path: string, replace?: boolean) => void,
  collapse: () => void,
) => {
  e.preventDefault();
  setIsSubmitted(true);
  rrHelper.dispatchSearchEvent(value);
  if (sanitizedValue) {
    analytics.sendEvent('GA:click', { position: 'search', name: value });
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'search',
      elementText: value,
    });
    const override = getOverride(value, selectedSuggestion);
    if (!!override) {
      const patchedUrl = patchUrlByLocale({ url: override });
      navigate(patchedUrl);
      collapse();
      addHistoryItem({
        title: value,
        url: patchedUrl,
      });
      return;
    }
    if (!isLmcode) {
      addHistoryFromSubmit(value, sanitizedValue, selectedSuggestion);
    }
    if (isLmcode && !productRedirectionUrl && !productRedirectionUrlLoading) {
      dispatch(ProductRedirectionEvents.request, { lmcode: sanitizedValue, regionId: currentRegionId });
      return;
    }
    if (selectedSuggestion?.categoryUrl) {
      navigate(patchUrlByLocale({ url: selectedSuggestion?.categoryUrl }));
      collapse();
      return;
    }
    const searchParams = getSearchParams(selectedSuggestion, sanitizedValue);
    const searchUrl = patchUrlByLocale({ url: `/search/?${searchParams}` });
    navigate(searchUrl);
    collapse();
    return;
  }
  // default list pinned items fix
  if (selectedSuggestion?.categoryUrl) {
    navigate(patchUrlByLocale({ url: selectedSuggestion?.categoryUrl }));
    collapse();
  }
};
