import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { LinkButton } from 'fronton-react';
import { Region } from 'types/model';
import { analytics, getDatePlusYear, getDomain } from 'utils';
import { UseGetRegionLinkReturnType } from 'components/header/hooks';
import clsx from 'clsx';
import { CookiesEvents } from 'actions';
import { useStoreon } from 'storeon/react';
import { regionLink } from './styles';
import { stretchedLink } from '../../main-menu/styles';
import { prependRegionToUrl, deleteFacetQuery } from './utils';
import { ELIGIBILITY_BY_STORES_FACET } from './constants';

export interface RegionLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  region: Region;
  hasRegionNumberChanged?: boolean;
  focused?: boolean;
  getRegionLink: UseGetRegionLinkReturnType;
}

export const RegionLink = (props: RegionLinkProps) => {
  const { region, hasRegionNumberChanged, focused = false, getRegionLink, ...rest } = props;
  const { regionId, regionName, regionCode } = region;
  const { dispatch } = useStoreon('cookies');

  const elementRef = useRef<HTMLAnchorElement | null>();

  const sendGaAndSetCookie = () => {
    dispatch(CookiesEvents.update, {
      key: '_userRegion',
      value: regionCode,
      params: {
        path: '/',
        domain: `.${getDomain(window.location.hostname)}`,
        expires: getDatePlusYear(),
      },
    });
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: hasRegionNumberChanged === undefined ? 'chooseRegionSuggest' : 'chooseRegionHint',
      elementText: regionName,
    });
  };

  const hrefWithRegion = prependRegionToUrl(window.location.href ?? '', regionCode);

  const resultHref = deleteFacetQuery(getRegionLink(hrefWithRegion), ELIGIBILITY_BY_STORES_FACET);

  useEffect(() => {
    if (focused && elementRef.current) {
      elementRef.current.focus();
    }
  }, [focused]);

  return (
    <LinkButton
      ref={elementRef as React.RefObject<HTMLAnchorElement>}
      role="listitem"
      as="a"
      onClick={sendGaAndSetCookie}
      href={resultHref}
      data-qa={`region-link-${regionId}`}
      className={clsx(regionLink, stretchedLink)}
      {...rest}
    >
      {regionName}
    </LinkButton>
  );
};
