import { css } from 'linaria';

export const popover = css`
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: var(--space-100) 0;

  > div:last-of-type {
    display: none;
  }
`;

export const shoppingListLink = css`
  /* NOTE: Необходимо для повышения специфичности и перезаписи стилей из LinkButton. */
  &.shoppingListLinkHackSpecific {
    width: 100%;
    justify-content: flex-start;
    padding: var(--space-100) var(--space-150);
    border-radius: 0 !important;
  }
`;

export const shoppingListLinkText = css`
  margin-left: var(--space-100);
  margin-right: auto;
`;

export const shoppingListLinkMobile = css`
  &.shoppingListLinkHackSpecific {
    padding: var(--space-150) var(--space-200);
  }
`;

export const modalStyle = css`
  padding-bottom: var(--space-200);
`;
