import React, { useCallback, useState } from 'react';
import { ArrowUpRightIcon } from '@fronton/icons-react';
import {
  categorySuggestionIcon,
  categorySuggestionIconLoading,
  suggestionIconWrap,
} from './suggestion-list-item-styles';
import { useOptimizedImages } from 'common/hooks/use-optimized-images';

type Props = {
  src: string | undefined;
  alt: string;
};

export const SuggestionListItemCategoryImage: React.FC<Props> = ({ src, alt }) => {
  const [loading, setLoading] = useState(true);
  const [shoulRenderFallback, setShoulRenderFallback] = useState(!src);

  const onError = useCallback(() => {
    setShoulRenderFallback(true);
  }, [setShoulRenderFallback]);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const optimizedImage = useOptimizedImages(
    src,
    {
      rest: {
        h: '40',
        w: '40',
      },
    },
    {
      b: 'transparent',
      c: 'pad',
    },
  );

  return (
    <>
      {shoulRenderFallback ? (
        <div className={suggestionIconWrap}>
          <ArrowUpRightIcon type="outline" size="l" color="text-minor" />
        </div>
      ) : (
        <>
          <img
            data-qa="category-suggestion-button-icon"
            src={optimizedImage.rest?.x || ''}
            srcSet={`${optimizedImage.rest?.xx} 2x`}
            className={categorySuggestionIcon}
            alt={alt}
            onError={onError}
            onLoad={onLoad}
          ></img>
          {loading && <div className={categorySuggestionIconLoading}></div>}
        </>
      )}
    </>
  );
};
