import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSearch } from '@okapi/router-react';
import { useUpdateEffect } from 'common/hooks';
import { useDebounced } from 'common/hooks/use-debounced';
import { Suggestions } from 'types/suggestions';
import { patchUrlByLocale } from 'utils';
import { RouterContext } from '@okapi/router-react/dist/Provider';
import { useStoreon } from 'storeon/react';
import { ProductRedirectionUrl } from 'types/product-url';
import { Region } from 'types/model';
import { ProductRedirectionEvents } from 'actions';
import { useKeyboardNavigation } from 'common/hooks/use-keyboard-navigation';
import { handleSubmit, normalizeInput } from './utils';
import { TransitionState } from '../../types';
import { useSearchHistoryContext } from '../use-search-history-context';
import { sanitizeString } from './sanitize-string';
import { prepareSuggestions } from './prepare-suggestions';

export type UseSearchbarLogicParams = {
  getSuggestions: (value: string) => void;
  resetServices: () => void;
  collapse: () => void;
  transitionState: TransitionState;
  suggestions: Suggestions | null;
  suggestionsLoading?: boolean;
  error?: boolean;
  productRedirectionUrl?: ProductRedirectionUrl['redirectionUrl'];
  productRedirectionUrlLoading?: boolean;
  currentRegionId?: Region['regionId'];
  resetSponsoredProducts: () => void;
};

export const useSearchbarLogic = (params: UseSearchbarLogicParams) => {
  const {
    getSuggestions,
    resetServices,
    transitionState,
    collapse,
    productRedirectionUrl,
    productRedirectionUrlLoading,
    currentRegionId,
    suggestions,
    resetSponsoredProducts,
  } = params;

  const router = useContext(RouterContext);
  const path = router.getPath();
  const { navigate } = router;
  const { dispatch } = useStoreon();

  const [search] = useSearch();
  const [isSrp, setIsSrp] = useState(path.startsWith(patchUrlByLocale({ url: '/search/' })));

  const [initialValue, setInitialValue] = useState((search.q as string) ?? '');
  const [value, setValue] = useState(initialValue);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { addHistoryFromSubmit, addHistoryItem } = useSearchHistoryContext();

  const { slicedSuggestions, flatSuggestions } = useMemo(() => {
    return prepareSuggestions(suggestions, value);
  }, [suggestions, value]);

  const {
    index,
    reset,
    selectedItem: selectedSuggestion,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
  } = useKeyboardNavigation(flatSuggestions, { canUnselectAllItems: true });

  const handleGetSuggestions = useCallback(
    (searchString: string) => {
      getSuggestions(searchString);
    },
    [getSuggestions],
  );

  const dGetSuggestions = useDebounced(handleGetSuggestions, { interval: 300 });

  const prettifiedValue = useMemo(() => sanitizeString(value), [value]);

  const isLmcode = useMemo(
    () => /^\d+$/.test(prettifiedValue) && prettifiedValue.length === 8,
    [prettifiedValue],
  );

  const sanitizedValue = selectedSuggestion?.phrase ?? prettifiedValue;

  useUpdateEffect(() => {
    setInitialValue((search.q as string) ?? '');
  }, [search]);

  useUpdateEffect(() => {
    setIsSrp(path.startsWith(patchUrlByLocale({ url: '/search/' })));
  }, [path]);

  useUpdateEffect(() => {
    if (isLmcode && !productRedirectionUrlLoading && isSubmitted) {
      if (productRedirectionUrl) {
        const patchedUrl = patchUrlByLocale({ url: productRedirectionUrl });
        navigate(patchedUrl);
        dispatch(ProductRedirectionEvents.refresh);
        setIsSubmitted(false);
        collapse();
        addHistoryItem({ title: value, url: patchedUrl });
      } else {
        const patchedUrl = patchUrlByLocale({ url: `/search/?q=${sanitizedValue}` });
        navigate(patchedUrl);
        setIsSubmitted(false);
        collapse();
        addHistoryItem({ title: value, url: patchedUrl });
      }
    }
  }, [isLmcode, productRedirectionUrlLoading, productRedirectionUrl]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(
        e,
        value,
        sanitizedValue,
        isLmcode,
        productRedirectionUrl,
        productRedirectionUrlLoading,
        selectedSuggestion,
        currentRegionId,
        setIsSubmitted,
        addHistoryFromSubmit,
        addHistoryItem,
        dispatch,
        navigate,
        collapse,
      );
    },
    [
      value,
      sanitizedValue,
      addHistoryFromSubmit,
      addHistoryItem,
      selectedSuggestion,
      isLmcode,
      productRedirectionUrl,
      productRedirectionUrlLoading,
      navigate,
      collapse,
      dispatch,
      currentRegionId,
    ],
  );
  const updateValue = useCallback(
    (nextValue: string) => {
      setValue(nextValue);
      reset();
      if (nextValue === '') {
        resetServices();
        resetSponsoredProducts();
      }
      dGetSuggestions(normalizeInput(nextValue));
    },
    [dGetSuggestions, reset, resetServices, resetSponsoredProducts],
  );

  useUpdateEffect(() => {
    if (transitionState === 'collapsed') {
      reset();
      updateValue(initialValue);
    }
  }, [initialValue, updateValue, transitionState, reset]);

  return {
    isSrp,
    onSubmit,
    value,
    updateValue,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    index,
    selectedSuggestion,
    slicedSuggestions,
  };
};
