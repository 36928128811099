import {
  HIDE_B2B_INTERNAL_ID,
  HIDE_SUBSCRIBE_INTERNAL_ID,
  SEASON_BANNER_2025_INTERNAL_ID,
} from 'common/constants/experiment';
import { Experiment, ModelJson, Region } from '../types/model';

export const isModelFetched = (model: ModelJson | null) => model !== null;

export const selectCurrentRegion = (regions?: Region[], regionId?: string) =>
  regions?.find(({ regionId: region }) => regionId === region) ?? null;

export const getIsSubscribeAvailable = (experiments?: Experiment[]) =>
  !experiments?.some(({ internalId }) => internalId === HIDE_SUBSCRIBE_INTERNAL_ID);
export const getIsB2BEnabled = (experiments?: Experiment[]) =>
  !experiments?.some(({ internalId }) => internalId === HIDE_B2B_INTERNAL_ID);

export const isEnableSeasonBanner2025 = (experiments?: Experiment[]): boolean =>
  !!experiments?.some((experiment) => experiment.internalId === SEASON_BANNER_2025_INTERNAL_ID);
