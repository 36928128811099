import { SlicedSuggestions } from 'types/suggestions';

const PINNED_ITEMS_COUNT = 2;

export const getProductElementIndex = (idx: number, isDefaultList: boolean) => {
  return isDefaultList ? PINNED_ITEMS_COUNT + idx : idx;
};

export const getCategoryElementIndex = (
  idx: number,
  suggestions: SlicedSuggestions,
  isDefaultList: boolean,
) => {
  const products = suggestions[0];
  const index = products.length + idx;
  return isDefaultList ? PINNED_ITEMS_COUNT + index : index;
};

export const getServiceElementIndex = (
  idx: number,
  suggestions: SlicedSuggestions,
  isDefaultList: boolean,
) => {
  const products = suggestions[0];
  const categories = suggestions[3];
  const index = products.length + categories.length + idx;
  return isDefaultList ? PINNED_ITEMS_COUNT + index : index;
};
