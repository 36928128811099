import { BookmarksIcon, BookmarkSimpleIcon, IntersectIcon } from '@fronton/icons-react';
import { Hint, RegularButton } from 'fronton-react';
import React, { FC, useRef, useState } from 'react';
import { popover } from './styles';
import { analytics, patchUrlByLocale } from 'utils';
import { useTranslation } from 'react-i18next';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { HFModelData } from 'types/model';
import { ShoppingListLink } from './components/shopping-list-link';
import { useClickOutside } from 'components/header/hooks/use-click-outside';
import { useHandleEscPress } from 'common/hooks/use-handle-esc-press';
import { useHeaderAnalyticsEvent } from 'components/header/hooks/analytics/use-header-analytics-event';

export interface ShoppingButtonNewProps {
  model: HFModelData;
}

export const SHOPPING_LIST_BUTTON_NEW_TEST_ID = 'shopping-list-button-new';
export const SHOPPING_LIST_LINK_TEST_ID_1 = 'shopping-list-link-1';
export const SHOPPING_LIST_LINK_TEST_ID_2 = 'shopping-list-link-2';

export const ShoppingButtonNew: FC<ShoppingButtonNewProps> = ({ model }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');
  const { shoppingList } = model.header || {};

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useClickOutside(containerRef, () => setIsOpen(false));
  useHandleEscPress(() => setIsOpen(false), isOpen);

  const handleShoppingListClick = useHeaderAnalyticsEvent('shoppingList');

  const handleCompareListClick = () => {
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'headerLists',
      elementText: 'comparisonList',
    });
  };

  const renderAnchor = () => (
    <RegularButton
      data-qa={SHOPPING_LIST_BUTTON_NEW_TEST_ID}
      onClick={handleClick}
      size="l"
      variant="secondary"
      rounded
      iconOnly
      label={t('header.main.user-menu.shopping-list')}
      disabled={isOpen}
    >
      <BookmarksIcon type="outline" size="l" color="text-primary" />
    </RegularButton>
  );

  return (
    <div>
      <Hint open={isOpen} anchor={renderAnchor()} placement="bottom-end" popoverClassName={popover}>
        <div ref={containerRef}>
          <ShoppingListLink
            iconLeft={BookmarkSimpleIcon}
            href={patchUrlByLocale({ url: shoppingList, pathname, lang })}
            text={t('header.main.user-menu.shopping-list')}
            onClick={handleShoppingListClick}
            dataQa={SHOPPING_LIST_LINK_TEST_ID_1}
          />
          <ShoppingListLink
            iconLeft={IntersectIcon}
            // TODO: Добавить ссылку на список сравнения.
            href={patchUrlByLocale({ url: '', pathname, lang })}
            text={t('header.main.user-menu.compare-list')}
            iconLeftType="duotone"
            onClick={handleCompareListClick}
            dataQa={SHOPPING_LIST_LINK_TEST_ID_2}
          />
        </div>
      </Hint>
    </div>
  );
};
