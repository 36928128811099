import { useCallback } from 'react';
import { useStoreon } from 'storeon/react';
import { useSearchbarLogic } from 'components/header/hooks';
import { State } from 'store/types';
import { getCustomerId } from 'selectors';
import type { ProductRedirectionStore, SuggestionsStore } from 'reducers';
import { createCookies, getLangFromCookies } from 'utils';
import { useGetSponsoredProducts } from 'components/header/hooks/use-get-sponsored-products';
import { useSponsoredProductsPlacementConfig } from 'components/header/hooks/use-sponsored-products-placements';
import { getRegionId } from './utils';

export type UseSearchBarLogicReturnType = {
  searchSuggestions: SuggestionsStore['suggestions'];
  getSuggestions: (value: string) => void;
  resetServices: () => void;
  productRedirection: ProductRedirectionStore['productRedirection'];
  resetSponsoredProducts: () => void;
};

/**
 * @description обёртка над хуком useSearchbarLogic для Header
 * @returns { UseSearchBarLogicReturnType }
 */
export const useSearchBarLogic = (): UseSearchBarLogicReturnType => {
  const { dispatch, suggestions, cookies, model, productRedirection } = useStoreon<State>(
    'cookies',
    'suggestions',
    'productRedirection',
    'model',
  );

  const { getSuggestions, resetServices } = useSearchbarLogic(dispatch);
  const { getSponsoredProducts, resetSponsoredProducts } = useGetSponsoredProducts(dispatch);
  const sponsoredProductConfig = useSponsoredProductsPlacementConfig();

  const getSuggestionsSafe = useCallback(
    (value: string) => {
      const regionId = getRegionId(model?.properties);
      const customerId = getCustomerId(cookies);
      const lang = getLangFromCookies(createCookies(cookies));

      if (regionId) {
        getSuggestions({
          query: value,
          region: regionId,
          customerId,
          lang,
        });
      }
      // запрос спонсорских товаров
      if (sponsoredProductConfig && regionId && value) {
        getSponsoredProducts({
          searchQuery: value,
          regionId,
          session: sponsoredProductConfig.session,
          placementId: sponsoredProductConfig.externalId || '',
        });
      }
    },
    [model?.properties, cookies, sponsoredProductConfig, getSuggestions, getSponsoredProducts],
  );

  return {
    searchSuggestions: suggestions,
    getSuggestions: getSuggestionsSafe,
    resetServices,
    productRedirection,
    resetSponsoredProducts,
  };
};
