export const VALIDATION_RULES: Record<string, string>[] = [
  { utm_medium: 'performance_cpc' },
  { utm_source: 'stroyportal_performance_cpc' },
  { utm_source: 'regmarkets.ru' },
];

export const getSearchParsed = () => {
  const searchString = __IS_SERVER__ ? '' : location.search;
  const locationSearch = new URLSearchParams(searchString);

  const result: Record<string, string> = {};
  locationSearch.forEach((value, key) => (result[key] = value));
  return result;
};

export const validateUtmLabels = (utmLabels: string[][]) =>
  utmLabels.some(([label, value]) => VALIDATION_RULES.some((rule) => rule[label] === value));

export const tryParseJson = (json: string) => {
  try {
    return JSON.parse(json) as string[][];
  } catch (e) {
    return [];
  }
};
