import React from 'react';
import { Typography, RegularButton } from 'fronton-react';
import clsx from 'clsx';
import { CategorySuggestion } from 'types/suggestions';
import { capitalize } from 'utils/capitalize';
import { useTranslation } from 'react-i18next';
import { patchUrlByLocale } from 'utils';
import { useSuggestionsButtonAction } from '../../hooks/use-suggestions-button-action';
import {
  suggestion,
  suggestionButton,
  suggestionText,
  suggestionCaption,
  suggestionContentWrap,
  suggestionTextContentWrap,
} from './suggestion-list-item-styles';
import { SuggestionListItemCategoryImage } from './suggestion-list-item-category-image';

export const DATA_QA_CATEGORY_SUGGESTION_BUTTON = 'category-suggestion-button';

export interface SuggestionListItemCategoriesProps {
  categorySuggestion: CategorySuggestion;
  elementIndex: number;
  navigationIndex: number;
  isSrp: boolean;
  suggestionSelector: string;
  buttonAction: ReturnType<typeof useSuggestionsButtonAction>;
}

export const SuggestionListItemCategory: React.FC<SuggestionListItemCategoriesProps> = ({
  categorySuggestion,
  elementIndex,
  navigationIndex,
  isSrp,
  buttonAction,
  suggestionSelector,
}) => {
  const { t } = useTranslation();

  const { categoryUrl, categoryName, categoryLabel, categoryIcon } = categorySuggestion;

  const buttonParams = {
    ...buttonAction(
      elementIndex,
      categoryUrl,
      {
        block: t('header.searchbar.categories'),
        index: elementIndex,
        text: categoryName,
      },
      capitalize(categoryName),
      categoryUrl,
    ),
  };

  return (
    <div
      key={elementIndex}
      role="option"
      id={`${elementIndex}`}
      aria-selected={elementIndex === navigationIndex}
      data-qa-header-search-suggestion=""
    >
      <RegularButton
        variant="pseudo"
        tabIndex={-1}
        className={clsx(suggestionSelector, suggestion, {
          active: elementIndex === navigationIndex,
          [suggestionButton]: isSrp,
        })}
        key={categoryName + categoryLabel}
        data-qa={DATA_QA_CATEGORY_SUGGESTION_BUTTON}
        {...buttonParams}
        href={patchUrlByLocale({ url: categoryUrl })}
      >
        <div className={suggestionContentWrap}>
          <SuggestionListItemCategoryImage src={categoryIcon} alt={capitalize(categoryName)} />
          <div className={suggestionTextContentWrap}>
            <Typography variant="m" size="body_short" className={suggestionText} color="text-primary">
              {capitalize(categoryName)}
            </Typography>
            <Typography variant="caption" color="text-secondary" className={suggestionCaption}>
              {capitalize(categoryLabel)}
            </Typography>
          </div>
        </div>
      </RegularButton>
    </div>
  );
};
