import { StoreonDispatch } from 'storeon';
import { useCallback } from 'react';
import type { SponsoredProductsParams } from 'types/sponsored-products';
import {
  FETCH_SPONSORED_PRODUCTS,
  SPONSORED_PRODUCTS_RESET_SERVICES,
  SponsoredProductsEvents,
} from 'actions';

export const useGetSponsoredProducts = (dispatch: StoreonDispatch<SponsoredProductsEvents>) => {
  const getSponsoredProducts = useCallback(
    (props: SponsoredProductsParams) => {
      dispatch(FETCH_SPONSORED_PRODUCTS.init, props);
    },
    [dispatch],
  );

  const resetSponsoredProducts = useCallback(() => {
    dispatch(SPONSORED_PRODUCTS_RESET_SERVICES);
  }, [dispatch]);

  return { getSponsoredProducts, resetSponsoredProducts };
};
