import { css } from 'linaria';

export const wrapper = css`
  position: relative;
  background-color: var(--brand-primary);
  height: var(--space-600);

  text-align: center;

  @media (--to-tablet) {
    height: var(--space-500);
  }
`;

export const stretchedLink = css`
  @media (hover: hover) {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: -1;
    }
  }
`;

export const garland = css`
  position: absolute;
  height: var(--space-600);

  top: 0;

  @media (--to-tablet) {
    display: none;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
    transform: scaleX(-1);
  }
`;

export const callToAction = css`
  max-height: 100%;
  width: auto;
`;
