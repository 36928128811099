import type { SponsoredProductsDTO, SponsoredProductsParams } from 'types/sponsored-products';

export type SponsoredProductsEvents = {
  'sponsored/FETCH_sponsored_products/init': SponsoredProductsParams;
  'sponsored/FETCH_sponsored_products/request': boolean;
  'sponsored/FETCH_sponsored_products/success': SponsoredProductsDTO;
  'sponsored/FETCH_sponsored_products/failure': boolean;
  'sponsored/FETCH_sponsored_products/cancel': undefined;
  'sponsored/RESET_SERVICES_PRODUCTS': undefined;
};

export const FETCH_SPONSORED_PRODUCTS = {
  init: 'sponsored/FETCH_sponsored_products/init',
  request: 'sponsored/FETCH_sponsored_products/request',
  success: 'sponsored/FETCH_sponsored_products/success',
  failure: 'sponsored/FETCH_sponsored_products/failure',
  cancel: 'sponsored/FETCH_sponsored_products/cancel',
} as const;

export const SPONSORED_PRODUCTS_RESET_SERVICES = 'sponsored/RESET_SERVICES_PRODUCTS' as const;
