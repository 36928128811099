import type { Store } from '../store/types';
import { FETCH_SPONSORED_PRODUCTS, SPONSORED_PRODUCTS_RESET_SERVICES } from '../actions';
import type { SponsoredProductsDTO } from '../types/sponsored-products';
import { getSponsoredProducts } from '../api';

export type SponsoredProductsStore = {
  sponsoredProducts: {
    data: SponsoredProductsDTO | null;
    loading: boolean;
    error: boolean;
  };
};

const initialState: SponsoredProductsStore = {
  sponsoredProducts: {
    data: null,
    loading: false,
    error: false,
  },
};

export const sponsoredProducts = (store: Store) => {
  store.on('@init', () => initialState);

  store.on(FETCH_SPONSORED_PRODUCTS.init, (_, props) => {
    const { orchestratorHost, useCredentials, apiKey, requestID } = store.get();

    store.dispatch(FETCH_SPONSORED_PRODUCTS.request, true);

    return getSponsoredProducts({ ...props }, { orchestratorHost, useCredentials, apiKey, requestID })
      .then((data) => store.dispatch(FETCH_SPONSORED_PRODUCTS.success, data))
      .catch(() => store.dispatch(FETCH_SPONSORED_PRODUCTS.failure, true));
  });

  store.on(FETCH_SPONSORED_PRODUCTS.request, (state) => ({
    sponsoredProducts: {
      ...state.sponsoredProducts,
      loading: true,
      error: false,
    },
  }));

  store.on(FETCH_SPONSORED_PRODUCTS.success, (_state, data) => ({
    sponsoredProducts: {
      data,
      loading: false,
      error: false,
    },
  }));

  store.on(FETCH_SPONSORED_PRODUCTS.failure, () => ({
    sponsoredProducts: { data: null, loading: false, error: true },
  }));

  store.on(SPONSORED_PRODUCTS_RESET_SERVICES, (state) => {
    if (state.sponsoredProducts.data != null)
      return {
        sponsoredProducts: {
          data: null,
          loading: state.sponsoredProducts.loading,
          error: state.sponsoredProducts.error,
        },
      };

    return state;
  });
};
