import { useCallback, useEffect, useRef, useState } from 'react';
import { useStoreon } from 'storeon/react';
import { getDatePlusYear, getDomain, getRegion } from 'utils';
import { CookiesEvents } from 'actions';
import { showRegionsNotification } from 'selectors';

export const REGION_SET = 'region:set';

export const useRegionSubmitLogic = (cookies: Record<string, string>, search: string, regionId?: string) => {
  const regionIdRef = useRef(regionId);
  const [showSubmitNotification, setShowSubmitNotification] = useState(
    regionId ? showRegionsNotification(cookies) : false,
  );
  const { dispatch } = useStoreon('cookies');

  useEffect(() => {
    document.body.dispatchEvent(new CustomEvent(REGION_SET, { detail: regionIdRef.current }));
    const hostname = window.location.hostname;
    const domain = getDomain(hostname);
    dispatch(CookiesEvents.update, {
      key: '_userRegion',
      value: getRegion(hostname, domain),
      params: {
        path: '/',
        domain: `.${domain}`,
        expires: getDatePlusYear(),
      },
    });
  }, []);

  const submitRegion = useCallback(() => {
    setShowSubmitNotification(false);
  }, []);

  return { showSubmitNotification, submitRegion };
};
