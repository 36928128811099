import { useStoreon } from 'storeon/react';
import { Experiment } from 'types/model';
import { APPSFLYER_SCRIPT_INTERNAL_ID } from 'common/constants/experiment';
import { useEffect, useMemo } from 'react';
import { addAppsflyerScript } from './add-appsflyer-script';

export const useAppsflyerScript = () => {
  const { model } = useStoreon('model');
  const appsflyerExperiment = useMemo(
    () =>
      model?.experiments?.find(
        ({ internalId }: Experiment) => internalId === APPSFLYER_SCRIPT_INTERNAL_ID,
      ) || {},
    [model],
  );

  useEffect(() => addAppsflyerScript(appsflyerExperiment.externalId), [appsflyerExperiment]);
};
