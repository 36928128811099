import { useState } from 'react';
import { useVisualViewportApi } from './use-visual-viewport-api';

type Size = string;
interface UpscaleParams {
  x2: boolean;
  size: Size;
}

export type Sizes = { [x: string]: { w?: string; h?: string } };
type Photos = {
  [key: string]: {
    x: string | null;
    xx: string | null;
  };
};

export type Options = {
  c?: string;
  b?: string;
};

export const useOptimizedImages = (src: string | null | undefined, sizes: Sizes, options?: Options) => {
  const [scaled, setScaled] = useState(false);

  useVisualViewportApi({ handle: 'resize' }, (viewport) => viewport.scale > 2 && setScaled(true));

  const upscaleQuality = (url: string, { x2 = false, size }: UpscaleParams) => {
    const drp = {
      scaled: {
        x: '2.0',
        xx: '4.0',
      },
      standart: {
        x: '1.0',
        xx: '2.0',
      },
    };

    const dprParameter = drp[scaled ? 'scaled' : 'standart'][x2 ? 'xx' : 'x'];
    const widthParameter = sizes[size].w ? `w_${sizes[size].w}` : '';
    const heightParameter = sizes[size].h ? `h_${sizes[size].h}` : '';

    return url.replace(
      /\/upload/,
      `/upload/${options ? `${buildOptions(options)},` : ''}f_auto,q_auto,dpr_${[
        dprParameter,
        widthParameter,
        heightParameter,
      ]
        .filter(Boolean)
        .join(',')}`,
    );
  };

  return Object.keys(sizes).reduce((acc, size) => {
    acc[size] = {
      // encodeURI is used for URLs with UTF-8 characters
      x: src ? upscaleQuality(encodeURI(src), { x2: false, size }) : null,
      xx: src ? upscaleQuality(encodeURI(src), { x2: true, size }) : null,
    };

    return acc;
  }, {} as Photos);
};

export const buildOptions = (options: Options) => {
  const result = [];

  for (const [k, v] of Object.entries(options)) {
    result.push(`${k}_${v}`);
  }

  return result.join(',');
};
