import { isEnableSeasonBanner2025 } from 'selectors';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { analytics, getDatePlusWeek, getDomain } from 'utils';
import { useSiteDomain } from '../components/searchbar/hooks/use-site-domain';
import { useIsCheckout } from './use-is-checkout';
import { useIsBasket } from './use-is-basket';
import { CookiesEvents } from 'actions';

export const SEASON_BANNER_2025_COOKIE_KEY = 'apro_season_banner_2025';
export const SEASON_BANNER_2025_COOKIE_VALUE = '1';

export const getIsPromoSeasonPage = (path: string): boolean => !!path.match(/\/promo\/pora-pora?(.*)/);

export const useIsEnableSeasonBanner = () => {
  const { model } = useStoreon<State>('model');
  return isEnableSeasonBanner2025(model?.experiments);
};

export const checkHasSeasonBannerCookie = (cookies: Record<string, string>) => {
  return cookies?.[SEASON_BANNER_2025_COOKIE_KEY] === SEASON_BANNER_2025_COOKIE_VALUE;
};

export const eventDetail = {
  elementType: 'banner',
  elementBlock: 'landingStartSezona',
  promotions: [
    {
      name: 'header',
      creative: 'pora-pora',
      position: '1',
    },
  ],
};

export type UseSeasonBannerReturnType = [boolean, () => void, () => void];

/**
 * @name useSeasonBanner
 * @description Проверяет можно ли показать сезонный баннер и возвращаем массив где первый элемент указывает нужно ли показать баннер,
 *  второй элемент является функцией которая устанавливает куку клиенту по клику, чтобы не показывать баннер повторно
 * @returns [boolean, fn]
 */
export const useSeasonBanner = (): UseSeasonBannerReturnType => {
  const { host, cookies, dispatch, path } = useStoreon<State>('host', 'cookies', 'path');

  const hasBannerCookie = checkHasSeasonBannerCookie(cookies);

  const isPromoSeasonPage = getIsPromoSeasonPage(path);

  const isCheckout = useIsCheckout();
  const isBasket = useIsBasket();

  const domain = getDomain(host);

  const { isKzDomain } = useSiteDomain(domain);

  const isFeatureEnable = useIsEnableSeasonBanner();

  const onBannerClick = () => {
    dispatch(CookiesEvents.update, {
      key: SEASON_BANNER_2025_COOKIE_KEY,
      value: SEASON_BANNER_2025_COOKIE_VALUE,
      params: {
        path: '/',
        domain: `.${getDomain(window.location.hostname)}`,
        expires: getDatePlusWeek(),
      },
    });

    analytics.sendEvent('GA:promoClick', eventDetail);
  };

  const showBanner =
    isFeatureEnable && !isKzDomain && !isPromoSeasonPage && !isCheckout && !isBasket && !hasBannerCookie;

  const onShow = () => {
    analytics.sendEvent('GA:promoView', eventDetail);
  };

  return [showBanner, onBannerClick, onShow];
};
