import React, { FC } from 'react';
import { LinkButton, LinkButtonSize } from 'fronton-react';
import { shoppingListLink, shoppingListLinkText } from '../styles';
import { ChevronRightIcon, IconComponent } from '@fronton/icons-react';
import { IconProps } from '@fronton/icons-react/types';
import { cx } from 'linaria';

type ShoppingListLinkProps = {
  iconLeft: IconComponent;
  href: string;
  text: string;
  iconLeftType?: IconProps['type'];
  hideIconRight?: boolean;
  size?: LinkButtonSize;
  className?: string;
  onClick?: () => void;
  dataQa?: string;
};

export const ShoppingListLink: FC<ShoppingListLinkProps> = ({
  iconLeft: IconLeft,
  href,
  text,
  iconLeftType = 'outline',
  hideIconRight = false,
  size = 's',
  className,
  onClick,
  dataQa,
}) => {
  const iconSize = size === 'm' ? 'l' : 'm';

  return (
    <LinkButton
      // NOTE: Необходимо shoppingListLinkHackSpecific для повышения специфичности и перезаписи стилей из LinkButton.
      className={cx(shoppingListLink, 'shoppingListLinkHackSpecific', className)}
      iconLeft={<IconLeft type={iconLeftType} size={iconSize} color="text-primary" />}
      iconRight={
        hideIconRight ? undefined : <ChevronRightIcon type="outline" size="m" color="text-primary" />
      }
      size={size}
      variant="normal"
      as="a"
      href={href}
      onClick={onClick}
      data-qa={dataQa}
    >
      <span className={shoppingListLinkText}>{text}</span>
    </LinkButton>
  );
};
