import { useMemo } from 'react';
import { State } from 'store/types';
import { useStoreon } from 'storeon/react';
import { Experiment } from 'types/model';

export const SPONSORED_PRODUCTS_PLACEMENT = 'monetization_products_search';

export interface UseSponsoredProductsPlacementConfigResult extends Experiment {
  active: boolean;
  session: string;
}

export const useSponsoredProductsPlacementConfig = (): UseSponsoredProductsPlacementConfigResult | null => {
  const { model, cookies } = useStoreon<State>('model', 'cookies');
  const { rcuid } = cookies;

  const findExperiment = model?.experiments?.find(
    ({ internalId }) => internalId === SPONSORED_PRODUCTS_PLACEMENT,
  );

  return useMemo(() => {
    if (findExperiment && rcuid) {
      return {
        active: true,
        ...findExperiment,
        session: rcuid,
      };
    }

    return null;
  }, [findExperiment, rcuid]);
};
