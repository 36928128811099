import { BookmarkSimpleIcon, BookmarksSimpleIcon, IntersectIcon } from '@fronton/icons-react';
import React, { FC, useState } from 'react';
import { MobileMenuLink } from '../menu-mobile/mobile-menu-link';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader } from 'fronton-react';
import { ShoppingListLink } from './components/shopping-list-link';
import { modalStyle, shoppingListLinkMobile } from './styles';
import { useHeaderAnalyticsEvent } from 'components/header/hooks';
import { analytics } from 'utils';

type ShoppingListMenuMobileProps = {
  shoppingListHref: string;
  comparisonListHref: string;
};

export const MOBILE_MENU_LISTING_LINK_TEST_ID = 'mobile-menu-listing-link';
export const SHOPPING_LIST_LINK_MOBILE_TEST_ID_1 = 'shopping-list-link-mobile-1';
export const SHOPPING_LIST_LINK_MOBILE_TEST_ID_2 = 'shopping-list-link-mobile-2';

export const ShoppingListMenuMobile: FC<ShoppingListMenuMobileProps> = ({
  shoppingListHref,
  comparisonListHref,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setShow(true);
  };

  const handleShoppingListClick = useHeaderAnalyticsEvent('shoppingList');

  const handleCompareListClick = () => {
    analytics.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'header',
      elementName: 'headerLists',
      elementText: 'comparisonList',
    });
  };

  return (
    <>
      <MobileMenuLink
        icon={BookmarksSimpleIcon}
        href="/"
        text={t('header.main.listing')}
        getIsActive={() => false}
        data-qa={MOBILE_MENU_LISTING_LINK_TEST_ID}
        onClick={onClick}
      />

      <Modal className={modalStyle} show={show} onClose={() => setShow(false)} variant="curtain">
        <ModalHeader />
        <ShoppingListLink
          className={shoppingListLinkMobile}
          iconLeft={BookmarkSimpleIcon}
          href={shoppingListHref}
          text={t('header.main.user-menu.shopping-list')}
          hideIconRight
          size="m"
          onClick={handleShoppingListClick}
          dataQa={SHOPPING_LIST_LINK_MOBILE_TEST_ID_1}
        />
        <ShoppingListLink
          className={shoppingListLinkMobile}
          iconLeft={IntersectIcon}
          href={comparisonListHref}
          text={t('header.main.user-menu.compare-list')}
          iconLeftType="duotone"
          hideIconRight
          size="m"
          onClick={handleCompareListClick}
          dataQa={SHOPPING_LIST_LINK_MOBILE_TEST_ID_2}
        />
      </Modal>
    </>
  );
};
