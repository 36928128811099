import { useEffect, useRef, useState } from 'react';
import { isBrowser } from 'fronton-react/utils';

export type Params = {
  handle?: Handle;
};

type Handle = 'resize' | 'scroll' | 'both';

export const useVisualViewportApi = (
  { handle = 'both' }: Params = {},
  callback?: (viewport: VisualViewport) => void,
) => {
  useEffect(() => {
    if (callback) {
      subscribe((event: VisualViewportEvent) => callback(event.target), handle);
    }
  }, [callback, handle]);

  const supportedRef = useRef(isBrowser() && 'visualViewport' in window);

  const [visualViewport, setVisualViewport] = useState<{ height: number } | null>(
    supportedRef.current ? { height: window.visualViewport.height } : null,
  );

  useEffect(() => {
    if (!supportedRef.current) return () => {};
    return subscribe(({ target }: VisualViewportEvent) => {
      setVisualViewport({ height: target.height });
    }, handle);
  }, [supportedRef, handle]);

  return [visualViewport, supportedRef.current] as const;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscribe = (cb: (event: any) => void, handle: Handle): (() => void) => {
  if (['both', 'resize'].includes(handle)) {
    window.visualViewport.addEventListener('resize', cb, { passive: true });
  }

  if (['both', 'scroll'].includes(handle)) {
    window.visualViewport.addEventListener('scroll', cb, { passive: true });
  }

  return () => {
    window.visualViewport.removeEventListener('resize', cb);
    window.visualViewport.removeEventListener('scroll', cb);
  };
};
