import React, { useRef, useMemo, memo } from 'react';
import { SponsoredProductItemWithClickEvent } from './sponsored-product-item';
import { useSponsoredProductEvents } from 'common/hooks';
import { SponsoredProductItemProps } from 'types/sponsored-products';
import { labelsGaNames } from './utils';

export const SponsoredListItemProduct = memo((props: SponsoredProductItemProps & { contentId: string }) => {
  const {
    contentId,
    productId,
    displayedName,
    productPriceCategory,
    price: { mainPrice, previousPrice, additionalPrice, previousAdditionalPrice, additionalAsMain },
    searchQuery,
    productPosition,
  } = props;

  const productRef = useRef<HTMLDivElement | null>(null);

  const displayedPreviousPrice = additionalAsMain ? previousAdditionalPrice : previousPrice;
  const displayedMainPrice = additionalAsMain ? additionalPrice : mainPrice;
  const productPrevPrice = displayedPreviousPrice ? displayedPreviousPrice.toFixed(2) : '';
  const productPrice = displayedMainPrice ? displayedMainPrice.toFixed(2) : '';

  const gaTag = useMemo(() => {
    if (productPriceCategory === 'STD' && !productPrevPrice) return '';
    if (productPriceCategory && productPriceCategory !== 'STD' && productPrice < productPrevPrice)
      return `${labelsGaNames[productPriceCategory]}, discount`;
    return productPriceCategory ? labelsGaNames[productPriceCategory] : '';
  }, [productPriceCategory, productPrevPrice, productPrice]);

  const { handleClickEvent } = useSponsoredProductEvents({
    productRef,
    contentId,
    searchQuery: searchQuery,
    productId: String(productId),
    productName: displayedName,
    productPosition: String(productPosition),
    productPrevPrice: productPrevPrice,
    productPrice: productPrice,
    productTag: gaTag,
  });

  return (
    <SponsoredProductItemWithClickEvent
      ref={productRef}
      onPointerUpWithoutScroll={handleClickEvent}
      key={productId}
      {...props}
      searchQuery={searchQuery}
    />
  );
});
