import React, { forwardRef } from 'react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { patchUrlByLocale } from 'utils';
import { HeaderModelData } from 'types/model';
import { Divider } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import {
  MagnifyingGlassIcon,
  BookmarkSimpleIcon,
  ShoppingCartSimpleIcon,
  IconComponent,
} from '@fronton/icons-react';
import { useLocation } from '@okapi/router-react';
import { useMobileMenuAnalyticsEvent, useOrderStatusOn } from 'components/header/hooks';
import { NAStore } from 'components/icons/nastore';
import { getIsB2BEnabled } from 'selectors';
import { MobileMenuLink } from './mobile-menu-link';
import { wrapper, root, dividerFix } from './styles';
import { matchRoute } from './utils';
import { BasketMenuLink } from './basket-menu-link';
import { MobileUserMenu } from './mobile-user-menu';
import { ShoppingListMenuMobile } from '../shopping-list';
import { useAllowComparison } from 'common/hooks/use-allow-comparison';

export type MenuMobileProps = {
  model: HeaderModelData;
};

export const MenuMobile = forwardRef<HTMLDivElement, MenuMobileProps>(({ model }, ref) => {
  const { home, shoppingList, shops, initUrl } = model.header;
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');

  const [location] = useLocation();

  const { t } = useTranslation();

  const getIsActive = (href: string) => matchRoute(location, href);

  const onMainPageLinkClick = useMobileMenuAnalyticsEvent('Главная');
  const onShoppingListLinkClick = useMobileMenuAnalyticsEvent('Мой список');
  const onShopsLinkClick = useMobileMenuAnalyticsEvent('Магазины');

  const isOrderStatusOn = useOrderStatusOn(model);
  const isB2BEnabled = getIsB2BEnabled(model.experiments);

  const isAllowComparison = useAllowComparison();

  return (
    <div className={wrapper} data-qa="mobile-fixed-menu" ref={ref}>
      <Divider className={dividerFix} />
      <div className={root}>
        <MobileMenuLink
          icon={MagnifyingGlassIcon}
          href={patchUrlByLocale({ url: home, pathname: location, lang })}
          text={t('header.main.main-page')}
          getIsActive={getIsActive}
          data-qa="mobile-menu-home-link"
          onClick={onMainPageLinkClick}
        />

        {isAllowComparison ? (
          <ShoppingListMenuMobile
            shoppingListHref={patchUrlByLocale({ url: shoppingList, pathname: location, lang })}
            // TODO: Добавить ссылку на список сравнения
            comparisonListHref={''}
          />
        ) : (
          <MobileMenuLink
            icon={BookmarkSimpleIcon}
            href={patchUrlByLocale({ url: shoppingList, pathname: location, lang })}
            text={t('header.main.shopping-list')}
            getIsActive={getIsActive}
            data-qa="mobile-menu-shopping-list-link"
            onClick={onShoppingListLinkClick}
          />
        )}

        <MobileMenuLink
          customIcon={NAStore as IconComponent}
          href={patchUrlByLocale({ url: shops, pathname: location, lang })}
          text={t('header.main.stores')}
          getIsActive={getIsActive}
          data-qa="mobile-menu-shops-link"
          onClick={onShopsLinkClick}
        />

        <MobileUserMenu
          href={initUrl}
          shoppingListUrl={patchUrlByLocale({ url: shoppingList, pathname: location, lang })}
          isOrderStatusOn={isOrderStatusOn}
          isB2BEnabled={isB2BEnabled}
        />

        <BasketMenuLink
          icon={ShoppingCartSimpleIcon}
          href={patchUrlByLocale({ url: '/basket/', pathname: location, lang })}
          text={t('header.main.basket')}
          getIsActive={getIsActive}
          data-qa="mobile-menu-basket-link"
        />
      </div>
    </div>
  );
});
