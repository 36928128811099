export const uomMap = new Map([
  ['WE', 'вых.'],
  ['EA', 'шт.'],
  ['NIU', 'шт.'],
  ['KG', 'кг'],
  ['KGM', 'кг'],
  ['SBE', 'секц.'],
  ['BE', 'уп.'],
  ['BX', 'кор.'],
  ['CAR', 'кор.'],
  ['CT', 'кор.'],
  ['BK', 'кор.'],
  ['LTR', 'л'],
  ['WTS', 'ватт'],
  ['WT', 'ватт'],
  ['L', 'л'],
  ['MTR', 'м'],
  ['MTK', 'м²'],
  ['M2', 'м²'],
  ['ML', 'мл'],
  ['MTQ', 'м³'],
  ['M3', 'м³'],
  ['M', 'м'],
  ['PAN', 'шт.'],
  ['PACK', 'уп.'],
  ['PK', 'уп.'],
  ['PCE', 'уп.'],
  ['LOT', 'пара'],
  ['DJ', 'полдня'],
  ['PREST', 'поставка'],
  ['PRS', 'пара'],
  ['RO', 'рул.'],
  ['BZ', 'уп.'],
  ['DAY', 'сут.'],
  ['TO', 'т'],
  ['TON', 'т'],
  ['H', 'ч'],
  ['GM', 'г'],
  ['G', 'г'],
  ['LNM', 'пог. м'],
]);

export const labelsGaNames = {
  PIM: 'onlineOnly',
  BPR: 'bestPrice',
  LOF: 'offerLimited',
  STD: 'discount',
} as const;
