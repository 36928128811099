import React, { memo } from 'react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { SlicedSuggestions } from 'types/suggestions';
import { useTranslation } from 'react-i18next';
import { useLang } from 'common/hooks';
import { getDomain } from 'utils';
import { useSuggestionsButtonAction } from '../hooks/use-suggestions-button-action';
import { SuggestionsList } from './suggestions-list/suggestions-list';
import { NotFoundSuggestion } from './not-found-suggestion';
import { SuggestionListItemProduct } from './suggestions-list/suggestion-list-item-product';
import { SuggestionListItemServices } from './suggestions-list/suggestion-list-item-services';
import { SuggestionListItemCategory } from './suggestions-list/suggestion-list-item-category';
import { SuggestionsDefaultListPinnedItems } from './suggestions-list/suggestions-default-list-pinned-items';
import { useSiteDomain } from '../hooks/use-site-domain';
import {
  getCategoryElementIndex,
  getProductElementIndex,
  getServiceElementIndex,
} from './utils/get-element-index';
import { useSponsoredProducts } from 'components/header/hooks';
import { SponsoredListItemProduct } from './sponsored-list/sponsored-list-item-product';

export type SuggestionsProps = {
  searchQuery: string;
  suggestions: SlicedSuggestions | null;
  onMouseEnter: (idx: number) => void;
  onMouseLeave: () => void;
  onSelectSuggestion?: () => void;
  searchHref: string;
  suggestionSelector: string;
  index: number;
  isSrp: boolean;
  notFoundMessage: string | false | undefined;
  collapse: () => void;
};

export const Suggestions = memo<SuggestionsProps>(
  ({
    searchQuery,
    suggestions,
    suggestionSelector,
    onMouseEnter,
    onMouseLeave,
    searchHref,
    index,
    isSrp,
    onSelectSuggestion,
    notFoundMessage,
    collapse,
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [slicedProducts, slicedServices, slicedPages, slicedCategories] = suggestions ?? [[], [], [], []];
    const buttonAction = useSuggestionsButtonAction({
      onMouseEnter,
      onMouseLeave,
      onSelectSuggestion,
      searchQuery,
    });
    const sponsoredProducts = useSponsoredProducts();
    const { host } = useStoreon<State>('host');
    const domain = getDomain(host);

    const { t } = useTranslation();
    const { isKzDomain } = useSiteDomain(domain);
    const { lang } = useLang();
    const showServices = !(isKzDomain || lang === 'kk');
    const showProducts = !(isKzDomain && lang === 'kk');
    const isDefaultList = searchQuery === '';
    const noSuggestions =
      slicedProducts.length === 0 && slicedServices.length === 0 && slicedCategories.length === 0;
    const hideSuggestions = !showProducts && !showServices && !isDefaultList;

    if (!suggestions) return null;

    if (noSuggestions || hideSuggestions) {
      if (notFoundMessage === false) {
        return null;
      }
      return (
        <NotFoundSuggestion
          notFoundMessage={notFoundMessage}
          searchHref={searchHref}
          searchQuery={searchQuery}
          collapse={collapse}
        />
      );
    }

    return (
      <>
        {isDefaultList && (
          <SuggestionsDefaultListPinnedItems
            buttonAction={buttonAction}
            index={index}
            isSrp={isSrp}
            showServices={showServices}
            suggestionSelector={suggestionSelector}
          />
        )}
        {showProducts && (
          <>
            <SuggestionsList
              suggestionSelector={suggestionSelector}
              withTitle={false}
              withDivider={isDefaultList}
              title={t('header.searchbar.products')}
              aria-label={t('header.searchbar.products-aria')}
              data-qa={'product-suggestions'}
            >
              {slicedProducts.map((item, idx) => (
                <SuggestionListItemProduct
                  productSuggestion={item}
                  elementIndex={getProductElementIndex(idx, isDefaultList)}
                  navigationIndex={index}
                  searchHref={searchHref}
                  buttonAction={buttonAction}
                  isSrp={isSrp}
                  suggestionSelector={suggestionSelector}
                  key={item.phrase}
                />
              ))}
            </SuggestionsList>
            <SuggestionsList
              suggestionSelector={suggestionSelector}
              withTitle={false}
              withDivider={slicedProducts.length > 0 && slicedCategories.length > 0}
              title={t('header.searchbar.categories')}
              aria-label={t('header.searchbar.categories-aria')}
              data-qa={'category-suggestions'}
            >
              {slicedCategories.map((item, idx) => (
                <SuggestionListItemCategory
                  categorySuggestion={item}
                  elementIndex={getCategoryElementIndex(idx, suggestions, isDefaultList)}
                  navigationIndex={index}
                  buttonAction={buttonAction}
                  isSrp={isSrp}
                  suggestionSelector={suggestionSelector}
                  key={item.serviceId}
                />
              ))}
            </SuggestionsList>
          </>
        )}
        {showServices && (
          <SuggestionsList
            suggestionSelector={suggestionSelector}
            withTitle={false}
            withDivider={
              (slicedProducts.length > 0 || slicedCategories.length > 0) && slicedServices.length > 0
            }
            title={t('header.searchbar.services')}
            aria-label={t('header.searchbar.services-aria')}
            data-qa={'service-suggestions'}
          >
            {slicedServices.map((item, idx) => (
              <SuggestionListItemServices
                servicesSuggestion={item}
                elementIndex={getServiceElementIndex(idx, suggestions, isDefaultList)}
                navigationIndex={index}
                buttonAction={buttonAction}
                isSrp={isSrp}
                suggestionSelector={suggestionSelector}
                key={item.serviceId}
              />
            ))}
          </SuggestionsList>
        )}
        {!isKzDomain && (
          <SuggestionsList
            suggestionSelector={suggestionSelector}
            withTitle={false}
            withDivider={slicedServices.length > 0 || slicedCategories.length > 0}
            title={t('header.searchbar.sponsored')}
            data-qa={'sponsored-product-suggestions'}
          >
            {sponsoredProducts.map((product, productIndex) => {
              return (
                <SponsoredListItemProduct
                  key={product.productId}
                  productPosition={productIndex + 1}
                  {...product}
                  searchQuery={searchQuery}
                />
              );
            })}
          </SuggestionsList>
        )}
      </>
    );
  },
);
