import { css } from 'linaria';

export const sponsoredHover = css`
  &:hover {
    background-color: var(--background-secondary);
  }
`;

export const priceStyles = css`
  display: flex;
  flex-direction: row;
  line-height: 24px;
  align-items: center;
  gap: 8px;
`;
export const percentStyles = css`
  width: 20px;
  height: 20px;
  background-color: var(--secondary-red-primary);
  font-size: 13px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  & span {
    margin-left: -1px;
  }
`;
export const previousPriceStyles = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  position: relative;
  margin-left: -4px;
  margin-top: 4px;
`;
export const previousPriceCurrencyStyles = css`
  margin-left: 4px;
`;

export const uomPriceStyles = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-left: 4px;
  position: relative;
`;

export const crossedStyles = css`
  margin-left: 4px;
  &:after {
    position: absolute;
    top: 50%;
    left: -2px;
    display: block;
    width: 108%;
    height: 1.5px;
    background: var(--secondary-red-primary);
    content: '';
  }
`;
export const discountedPriceStyles = css`
  color: #d22e1c;
`;
