export interface LoadRefreshScriptParams {
  refreshUrl: string;
  tokenRefreshScriptUrl: string;
}

export const loadRefreshScript = ({ refreshUrl, tokenRefreshScriptUrl }: LoadRefreshScriptParams) => {
  window.authConfig = {
    endpointUrl: refreshUrl,
  };

  const { head } = document;
  const exists = head?.querySelector(`script[src='${tokenRefreshScriptUrl}']`);

  if (!exists) {
    let el = document.createElement('script');
    el.async = true;
    el.src = tokenRefreshScriptUrl;
    head.appendChild(el);
  }
};
