import { analytics } from 'utils/analytics';

export interface SponsoredProductGAEventData {
  contentId: string;
  searchQuery: string;
  productName: string;
  productId: string;
  productPrice: string;
  productPosition: string;
  productPrevPrice: string;
  productTag: string;
}

const sponsoredProductImpressioned: string[] = [];
const sponsoredProductClicked: string[] = [];

export const sendSponsoredProductImpression = ({
  contentId,
  searchQuery,
  productName,
  productId,
  productPrice,
  productPosition,
  productPrevPrice,
  productTag,
}: SponsoredProductGAEventData) => {
  if (!!contentId && !sponsoredProductImpressioned.includes(contentId)) {
    analytics.sendEvent('GA:impressions', {
      elementType: 'product',
      elementBlock: 'headerSearch',
      elementName: 'searchProduct',
      eventVariant: searchQuery,
      productName,
      productId,
      productPrice,
      productPosition,
      productPrevPrice,
      productTag,
      isMonetization: 'true',
    });

    sponsoredProductImpressioned.push(contentId);
  }
};

export const sendSponsoredProductClick = ({
  contentId,
  searchQuery,
  productName,
  productId,
  productPrice,
  productPosition,
  productPrevPrice,
  productTag,
}: SponsoredProductGAEventData) => {
  if (!!contentId && !sponsoredProductClicked.includes(contentId)) {
    analytics.sendEvent('GA:clickProduct', {
      elementType: 'product',
      elementBlock: 'headerSearch',
      elementName: 'searchProduct',
      eventVariant: searchQuery,
      productName,
      productId,
      productPrice,
      productPosition,
      productPrevPrice,
      productTag,
      isMonetization: 'true',
    });

    sponsoredProductClicked.push(contentId);
  }
};
