import React, { useEffect, useRef } from 'react';
import { cx } from 'linaria';
import { stretchedLink, wrapper, garland, callToAction } from './styles';
import { BANNER_LINK_TEST_ID, BANNER_TEST_ID, CALL_TO_ACTION_IMG, GARLAND_LEFT_IMG } from './constants';
import { useOptimizedImages } from 'common/hooks/use-optimized-images';
import { useOnScreen } from 'common/hooks/use-on-screen';

export interface HeaderSeasonBannerProps {
  onBannerClick: () => void;
  onBannerShow: () => void;
}

export const HeaderSeasonBanner = ({ onBannerClick, onBannerShow }: HeaderSeasonBannerProps) => {
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const callToActionImage = useOptimizedImages(CALL_TO_ACTION_IMG, {
    desktop: {
      h: '48',
      w: '358',
    },
    mobile: {
      h: '40',
      w: '298',
    },
  });

  const garlandImage = useOptimizedImages(GARLAND_LEFT_IMG, {
    desktop: {
      h: '48',
      w: '61',
    },
  });

  const isOnScreen = useOnScreen(bannerRef);

  useEffect(() => {
    if (isOnScreen) {
      onBannerShow();
    }
  }, [isOnScreen, onBannerShow]);

  return (
    <div data-qa={BANNER_TEST_ID} className={wrapper} ref={bannerRef}>
      <img
        height="48"
        width="61"
        src={garlandImage.desktop?.x || ''}
        srcSet={`${garlandImage.desktop?.xx} 2x`}
        alt="garland left"
        className={cx(garland, 'left')}
      />
      <img
        height="48"
        width="61"
        src={garlandImage.desktop?.x || ''}
        srcSet={`${garlandImage.desktop?.xx} 2x`}
        alt="garland right"
        className={cx(garland, 'right')}
      />
      <a
        data-qa={BANNER_LINK_TEST_ID}
        onClick={onBannerClick}
        href="/promo/pora-pora/?&erid=2VSb5yWnsPe"
        className={stretchedLink}
      >
        <img
          width="358"
          height={'48'}
          src={callToActionImage.desktop?.x || ''}
          srcSet={`${callToActionImage.desktop?.xx} 2x`}
          alt="promo banner"
          className={callToAction}
        />
      </a>
    </div>
  );
};
