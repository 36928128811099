import React, { useRef } from 'react';
import { Adaptive, Layout } from 'fronton-react';
import { cx } from 'linaria';
import { HeaderModelData } from 'types/model';
import { useHideRegions } from 'common/hooks/use-hide-regions';
import {
  headerBackground,
  root,
  rootHiddenRegions,
  logoHiddenRegions,
  searchBarWrap,
  searchBarWrapHiddenRegions,
  subRoot,
  userGroup,
  wrapper,
} from './styles';
import { LogoButton } from '../logo-button';
import { CityPicker } from '../city-picker';
import { MenuButton } from '../menu-button';
import { SearchBarRoot } from '../searchbar';
import { Profile } from '../profile';
import { ShoppingButton } from '../shopping-list';
import { BasketButton } from '../basket';
import {
  useCookieNotification,
  useLogo,
  useHamburgerMenuState,
  useRegionSelect,
  useCityPickerState,
} from '../../hooks';
import { useMobileBottomPadding } from './hooks/use-mobile-bottom-padding';
import { useIsCheckout } from '../../hooks/use-is-checkout';
import { ShoppingButtonNew } from '../shopping-list/shopping-list-button-new';
import { useAllowComparison } from 'common/hooks/use-allow-comparison';
type Props = {
  model: HeaderModelData;
  isSticky?: boolean;
  isStickyVisible?: boolean;
};

export const HeaderContent: React.FC<Props> = ({ model, isSticky, isStickyVisible }) => {
  const { home } = useLogo(model);
  const { onMenuOpen } = useHamburgerMenuState();
  const isHideRegions = useHideRegions();
  const isAllowComparison = useAllowComparison();
  const isRegionsVisible = !isHideRegions;

  const regionsMenuState = useCityPickerState();
  const { onMenuOpen: onRegionMenuOpen } = regionsMenuState;

  const containerRef = useRef<HTMLDivElement>(null);
  const menuMobileRef = useRef<HTMLDivElement>(null);

  useCookieNotification();
  useRegionSelect({ model, onChangeClick: onRegionMenuOpen, isSticky });

  useMobileBottomPadding(menuMobileRef);

  const isCheckout = useIsCheckout();
  return (
    <>
      <div className={headerBackground} />
      <Layout>
        <div className={wrapper}>
          <div className={cx(root, isRegionsVisible ? '' : rootHiddenRegions)}>
            {!isSticky && (
              <>
                <LogoButton
                  home={home}
                  className={isRegionsVisible ? '' : logoHiddenRegions}
                  showMenuButton={!isCheckout}
                />
                {isRegionsVisible && (
                  <Adaptive to="tablet" data-qa="city-picker-wrap-to-tablet">
                    <CityPicker model={model} onRequestRegionChange={onRegionMenuOpen} />
                  </Adaptive>
                )}
              </>
            )}
            <div className={subRoot} ref={containerRef}>
              {!isCheckout && <MenuButton onOpenMenuClick={onMenuOpen} />}
              {!isCheckout && (
                <div className={cx(searchBarWrap, isRegionsVisible ? '' : searchBarWrapHiddenRegions)}>
                  <SearchBarRoot
                    model={model}
                    ref={containerRef}
                    hasForm={(isSticky && isStickyVisible) || (!isStickyVisible && !isSticky)}
                  />
                </div>
              )}
              {isRegionsVisible && (
                <Adaptive from="tablet" data-qa="city-picker-wrap-from-tablet">
                  <CityPicker model={model} onRequestRegionChange={onRegionMenuOpen} />
                </Adaptive>
              )}
            </div>
          </div>
          <Adaptive from="desktop">
            <div className={userGroup} data-qa="header-user-group">
              <Profile model={model} />
              {!isCheckout && isAllowComparison && <ShoppingButtonNew model={model} />}
              {!isCheckout && !isAllowComparison && <ShoppingButton model={model} />}
              <BasketButton />
            </div>
          </Adaptive>

          {isCheckout && (
            <>
              <Adaptive to="tablet">
                <Profile model={model} />
                <BasketButton />
              </Adaptive>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
