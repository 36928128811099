import React from 'react';
import {
  crossedStyles,
  priceStyles,
  percentStyles,
  discountedPriceStyles,
  previousPriceStyles,
  uomPriceStyles,
  previousPriceCurrencyStyles,
} from './styles';
import { SponsoredProduct } from 'types/sponsored-products';
import { formatPrice } from 'utils/format-price';
import { uomMap } from './utils';
import { cx } from 'linaria';
import { getCurrency } from 'utils/currency';
import { useNumberFormatter } from 'common/hooks';

export const SponsoredProductPrice = React.memo((props: SponsoredProduct) => {
  const {
    price: {
      mainPrice,
      mainUom,
      currency,
      previousPrice,
      additionalAsMain,
      additionalPrice,
      additionalUom,
      previousAdditionalPrice,
    },
  } = props;

  const currencyFormatter = useNumberFormatter({
    options: {
      currency: currency,
      currencyDisplay: 'narrowSymbol',
      style: 'currency',
    },
  });

  const priceToDisplay = formatPrice(additionalAsMain ? additionalPrice : mainPrice);
  const previousPriceToDisplay = formatPrice(additionalAsMain ? previousAdditionalPrice : previousPrice);

  const uomToDisplay = additionalAsMain ? additionalUom : mainUom;
  const currencyToDisplay = getCurrency(currencyFormatter);
  const uomCurrencyToDisplay = uomToDisplay
    ? `${currencyToDisplay}/${uomMap.get(uomToDisplay)}`
    : currencyToDisplay;

  const isPreviousPrice = additionalAsMain
    ? !!previousAdditionalPrice && !!additionalPrice && previousAdditionalPrice > additionalPrice
    : !!previousPrice && previousPrice > mainPrice;

  return (
    <div className={priceStyles} data-qa="header-sponsored-product-price">
      {isPreviousPrice && (
        <div className={percentStyles} data-qa="header-sponsored-product-price-percent">
          <span>%</span>
        </div>
      )}
      <span
        className={isPreviousPrice ? discountedPriceStyles : ''}
        data-qa="header-sponsored-product-price-main"
      >
        {priceToDisplay}
        <span className={uomPriceStyles} data-qa="header-sponsored-product-price-uom">
          {uomCurrencyToDisplay}
        </span>
      </span>
      {isPreviousPrice && (
        <span
          className={cx(previousPriceStyles, crossedStyles)}
          data-qa="header-sponsored-product-price-previous"
        >
          {previousPriceToDisplay}
          <span className={previousPriceCurrencyStyles} data-qa="header-sponsored-product-price-currency">
            {currencyToDisplay}
          </span>
        </span>
      )}
    </div>
  );
});
