import {
  CategorySuggestion,
  PageSuggestion,
  ProductSuggestion,
  ServiceSuggestion,
  SlicedSuggestions,
  Suggestion,
  Suggestions,
} from 'types/suggestions';

export type PreparedSuggestions = {
  slicedSuggestions: SlicedSuggestions | null;
  flatSuggestions: Suggestion[] | null;
};

export const prepareSuggestions = (suggestions: Suggestions | null, value: string): PreparedSuggestions => {
  if (suggestions) {
    const suggestionSlice = getSlicedSuggestions(
      suggestions.products,
      suggestions.services,
      suggestions.categories,
    );
    const defaultSuggestionsSlice = getDefaultSlicedSuggestions(suggestions.products);

    const result = value
      ? {
          slicedSuggestions: suggestionSlice,
          flatSuggestions: [...suggestionSlice[0], ...suggestionSlice[3], ...suggestionSlice[1]],
        }
      : {
          slicedSuggestions: defaultSuggestionsSlice,
          flatSuggestions: [
            ...defaultSuggestionsSlice[2],
            ...defaultSuggestionsSlice[0],
            ...defaultSuggestionsSlice[1],
          ],
        };

    return result;
  }

  return {
    slicedSuggestions: null,
    flatSuggestions: null,
  };
};

const MAX_SUGGESTIONS_NUMBER = 6;

export function getSlicedSuggestions(
  products: ProductSuggestion[],
  services: ServiceSuggestion[],
  categories: CategorySuggestion[],
): SlicedSuggestions {
  const slicedProducts =
    services.length <= MAX_SUGGESTIONS_NUMBER / 2
      ? products.slice(0, MAX_SUGGESTIONS_NUMBER - services.length)
      : products.slice(0, MAX_SUGGESTIONS_NUMBER / 2);
  const slicedServices =
    products.length <= MAX_SUGGESTIONS_NUMBER / 2
      ? services.slice(0, MAX_SUGGESTIONS_NUMBER - products.length)
      : services.slice(0, MAX_SUGGESTIONS_NUMBER / 2);
  const slicedCategories = categories.slice(0, MAX_SUGGESTIONS_NUMBER / 2);

  return [slicedProducts || [], slicedServices || [], [], slicedCategories || []];
}

export function getDefaultSlicedSuggestions(products: ProductSuggestion[]): SlicedSuggestions {
  const slicedProducts = products.slice(0, MAX_SUGGESTIONS_NUMBER);
  const defaultPages: PageSuggestion[] = [{ categoryUrl: '/promo/' }, { categoryUrl: '/uslugi/' }];
  return [slicedProducts || [], [], defaultPages, []];
}
