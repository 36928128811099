import { State } from 'store/types';
import { useStoreon } from 'storeon/react';

export const useSponsoredProducts = () => {
  const { sponsoredProducts } = useStoreon<State>('sponsoredProducts');
  const { data } = sponsoredProducts;
  //TODO вынести эту логику на BFF
  return data !== null
    ? data.content.map((productItem) => ({ ...productItem.products[0], contentId: productItem.id }))
    : [];
};
