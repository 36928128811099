import React, { useState, useCallback, forwardRef } from 'react';

function withPointerUpHandling<P>(WrappedComponent: React.ComponentType<P>) {
  const THRESHOLD = 5;
  const WithPointerUpHandlingComponent = forwardRef<
    HTMLDivElement,
    P & {
      onPointerUpWithoutScroll?: (e: PointerEvent) => void;
    }
  >((props, ref) => {
    const [startPosition, setStartPosition] = useState<{ x: number; y: number } | null>(null);
    const [isScrolling, setIsScrolling] = useState(false);

    const handlePointerDown = useCallback((e: React.PointerEvent<HTMLElement>) => {
      setStartPosition({ x: e.clientX, y: e.clientY });
      setIsScrolling(false);
    }, []);

    const handlePointerMove = useCallback(
      (e: React.PointerEvent<HTMLElement>) => {
        if (startPosition && !isScrolling) {
          const distanceX = Math.abs(e.clientX - startPosition.x);
          const distanceY = Math.abs(e.clientY - startPosition.y);
          if (distanceX > THRESHOLD || distanceY > THRESHOLD) {
            setIsScrolling(true);
          }
        }
      },
      [startPosition, isScrolling],
    );

    const handlePointerUp = useCallback(
      (e: React.PointerEvent<HTMLElement>) => {
        if (!isScrolling && props.onPointerUpWithoutScroll) {
          props.onPointerUpWithoutScroll(e.nativeEvent);
        }
        setStartPosition(null);
        setIsScrolling(false);
      },
      [isScrolling, props],
    );

    return (
      <span
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
      >
        <WrappedComponent ref={ref} {...props} />
      </span>
    );
  });
  return WithPointerUpHandlingComponent;
}

export default withPointerUpHandling;
