export const NEED_LOG_FLAG = 'rrLog';
const gaBasketAddEvent = 'GA:addToCart';
const rrSearchEvent = 'rr:search';

type GABasketEvent = CustomEvent<{
  productId: string;
}>;

type RRSearchEvent = CustomEvent<{
  phrase: string;
}>;

/**
 * @description Класс для работы с событиями RetailRocket
 */
export class RetailRocketHelper {
  needLog: boolean;

  viewedContentIds: string[];

  clickedContentIds: string[];

  constructor() {
    this.needLog = __IS_SERVER__ ? false : Boolean(localStorage.getItem(NEED_LOG_FLAG));
    this.viewedContentIds = [];
    this.clickedContentIds = [];
  }

  log = (message: string) => {
    if (this.needLog) {
      // eslint-disable-next-line no-console
      console.log('RR', message);
    }
  };

  getIsCookieAccepted = () => document.cookie.includes('cookie_accepted=true');

  dispatchSetAcceptedCookie = () => {
    document.dispatchEvent(new CustomEvent('rr:cookie_accepted'));
    this.log('cookie_accepted');
  };

  getStockID = () => {
    const matchRegion = document.cookie.match(/(_regionID=)(\d*)(;?)/);
    return matchRegion && matchRegion?.length >= 3 ? matchRegion[2] : '';
  };

  dispatchAddToCart = (event: GABasketEvent) => {
    const {
      detail: { productId },
    } = event;

    const stockId = this.getStockID();

    this.log(`stockId: ${stockId}, productId: ${productId}`);

    this.pushEvent(() => {
      try {
        window?.rrApi?.addToBasket(productId, { stockId });
      } catch (e) {
        this.log(`rrApi addToBasket error`);
      }
    });
  };

  listenAddToCart = () => {
    document.addEventListener(gaBasketAddEvent, this.dispatchAddToCart as EventListener, { passive: true });
  };

  pushEvent = (rrEvent: unknown) => {
    (window.rrApiOnReady = window?.rrApiOnReady || []).push(rrEvent);
  };

  dispatchSearchEvent = (phrase: string) => {
    this.log(`rr search phrase ${phrase}`);
    this.pushEvent(() => {
      try {
        window?.rrApi?.search(phrase);
      } catch (e) {
        this.log(`rrApi search error`);
      }
    });
  };

  /**
   * Для обработки поиска на главной
   * document.dispatchEvent('rr:search', { phrase: 'поисковая фраза' })
   */
  listenSearch = () => {
    document.addEventListener(rrSearchEvent, (event) => {
      const {
        detail: { phrase },
      } = event as RRSearchEvent;
      this.dispatchSearchEvent(phrase);
    });
  };

  dispatchContentClickedEvent = (impressionContentId: string) => {
    if (this.clickedContentIds.includes(impressionContentId)) return;

    this.log(`content clicked ${impressionContentId}`);
    this.pushEvent(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.rrApi?.impressionContentClicked({
          impressionContentId,
        });
      } catch {
        this.log(`rrApi content clicked error`);
      }
    });

    this.clickedContentIds.push(impressionContentId);
  };

  dispatchContentViewedEvent = (impressionContentId: string) => {
    if (this.viewedContentIds.includes(impressionContentId)) return;

    this.log(`content viewed ${impressionContentId}`);
    this.pushEvent(() => {
      try {
        window?.rrApi?.impressionContentViewed({
          impressionContentId,
        });
      } catch {
        this.log(`rrApi content viewed error`);
      }
    });

    this.viewedContentIds.push(impressionContentId);
  };
}

export const rrHelper = new RetailRocketHelper();

if (!__IS_SERVER__) {
  window.LMRR = window.LMRR || { rrHelper };
}
