import React, { forwardRef } from 'react';
import { Typography, RegularButton } from 'fronton-react';
import { cx } from 'linaria';
import { SponsoredProductItemProps } from 'types/sponsored-products';
import {
  suggestion,
  suggestionText,
  suggestionCaption,
  suggestionContentWrap,
  suggestionIconWrap,
  suggestionTextContentWrap,
} from '../suggestions-list/suggestion-list-item-styles';
import { sponsoredHover } from './styles';
import { patchUrlByLocale } from 'utils';
import { shortenDisplayedText } from 'utils/shorten-displayed-text';
import withPointerUpHandling from 'common/hocs/with-pointer-up-handling';
import { SponsoredProductPrice } from './sponsored-product-price';

export const DATA_QA_SPONSORED_SUGGESTION_BUTTON = 'sponsored-suggestion-button';

export const SponsoredProductItem = forwardRef<HTMLDivElement, SponsoredProductItemProps>((props, ref) => {
  const { productId, displayedName, productPhoto, productPageName, searchQuery } = props;
  const href = `${productPageName}/?q=${searchQuery}&suggest=true`;

  return (
    <div role="option" id={`${productId}`} ref={ref}>
      <RegularButton
        variant="pseudo"
        tabIndex={-1}
        className={cx(suggestion, sponsoredHover)}
        key={productId}
        data-qa={DATA_QA_SPONSORED_SUGGESTION_BUTTON}
        href={patchUrlByLocale({ url: href })}
      >
        <div className={suggestionContentWrap}>
          <div className={cx(suggestionIconWrap)} style={{ border: 'none' }}>
            <img src={productPhoto} alt={displayedName} />
          </div>
          <div className={suggestionTextContentWrap}>
            <Typography
              variant="caption"
              color="text-primary"
              className={suggestionCaption}
              data-qa="header-sponsored-product-name"
            >
              {shortenDisplayedText(displayedName, 74)}
            </Typography>
            <Typography variant="m" size="body_short" className={suggestionText} color="text-primary">
              <SponsoredProductPrice {...props} />
            </Typography>
          </div>
        </div>
      </RegularButton>
    </div>
  );
});

export const SponsoredProductItemWithClickEvent = withPointerUpHandling(SponsoredProductItem);
