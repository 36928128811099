import {
  sendSponsoredProductClick,
  sendSponsoredProductImpression,
  SponsoredProductGAEventData,
} from 'components/header/components/searchbar/components/sponsored-list/ga-events';
import { useOnScreen } from './use-on-screen';
import { rrHelper } from 'utils/retail-rocket';

type SponsoredProductEventsOptions = {
  productRef: React.MutableRefObject<HTMLDivElement | null>;
} & SponsoredProductGAEventData;

export const useSponsoredProductEvents = (options: SponsoredProductEventsOptions) => {
  const { productRef, ...gaOptions } = options;
  const { contentId } = options;

  const isOnScreen = useOnScreen(productRef, {
    threshold: 0.5,
  });

  const handleClickEvent = (e: PointerEvent) => {
    if (!contentId || e.button === 2) return;

    rrHelper.dispatchContentClickedEvent(contentId);
    sendSponsoredProductClick(gaOptions);
  };

  if (isOnScreen && contentId) {
    rrHelper.dispatchContentViewedEvent(contentId);
    sendSponsoredProductImpression(gaOptions);
  }

  return {
    handleClickEvent,
  };
};
