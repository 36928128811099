import React from 'react';
import { registerWidget } from '@okapi/core';
import { getGlobalRouter } from '@okapi/router-react';
import { createStore } from 'store';
import { State } from 'store/types';
import { CookiesEvents, FETCH_MODEL, GlobalEvents } from 'actions';
import { initI18n } from 'utils/init-i18n';
import { Header } from '../../common';
import { HeaderLogicProps } from '../../../components';
import { rrHelper } from 'utils/retail-rocket';

registerWidget<State, HeaderLogicProps>(`${__NAME__}/header`, (state) => {
  const router = getGlobalRouter();
  const store = createStore();
  store.dispatch(GlobalEvents.setRequestParams, {
    orchestratorHost: state.orchestratorHost,
    orchestratorHostKz: state.orchestratorHostKz,
    useCredentials: state.useCredentials,
    apiKey: state.apiKey,
  });
  store.dispatch(GlobalEvents.setRequestID, { requestID: state.requestID });
  store.dispatch(GlobalEvents.setIsBot, state.isBot);
  store.dispatch(GlobalEvents.setLogoProUrl, state.logoProUrl ?? '');
  store.dispatch(GlobalEvents.setUserAgent, state.userAgent);
  store.dispatch(CookiesEvents.set, document.cookie);
  store.dispatch(GlobalEvents.setPathname, state.path ?? '/');
  store.dispatch(GlobalEvents.setHost, state.host);
  store.dispatch(FETCH_MODEL.success, state.model ?? undefined);
  initI18n(router.getPath(), state.host);
  rrHelper.listenAddToCart();
  rrHelper.listenSearch();

  return (remoteProps) => <Header router={router} store={store} {...remoteProps} />;
});
