export const isUserAuth = (cookie: Record<string, string>) => cookie['user-auth']?.length > 0 ?? false;

export const getCustomerId = (cookie: Record<string, string>) => cookie.customerId ?? cookie?._ym_uid;

export const getLastRegion = (cookie: Record<string, string>) => cookie._regionID;

export const getUserRegion = (cookie: Record<string, string>) => cookie._userRegion;

export const showRegionsNotification = (cookie: Record<string, string>) => !getUserRegion(cookie);

export const cookieAccepted = (cookie: Record<string, string>) => cookie.cookie_accepted === 'true';

export const getUserData = (cookie: Record<string, string>) => cookie?.userData;

export const getUtmLabels = (cookie: Record<string, string>) => cookie?.utm_labels;

export const getLang = (cookie: Record<string, string>): string => cookie?.lang ?? 'ru';
